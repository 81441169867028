/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { eventCallbackTriggers } from "../templates/wppage";
import DonationLayout from "../layout/DonationLayout";

const DonationKanhaBuildingMobile = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Donation for Green Kanha";
    return `<gatsby_donation donationId = "${id}" amount=0 rate=1000 btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Donation for Kanha Building" allowOverseas = false/>`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("donation_for_kanha_building", event),
    []
  );

  return (
    <DonationLayout
      seoData={{
        title: "Donation - Kanha Building [Mobile] - Donations",
      }}
    >
      <Grid
        columns={1}
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Box style={{ color: "#2d3550" }}>
          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                color: "#2d3550",
                fontSize: "1.5em",
                fontWeight: "600",
                marginTop: "40px",
              }}
            >
              Donation for SMSF India General Fund Kanha Building
            </h1>
          </div>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent(11, "Contribute Now")}
          />
        </Box>
      </Grid>
    </DonationLayout>
  );
};

export default DonationKanhaBuildingMobile;
